<template>
  <div class="container-fluid">
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="row">
          <div class="col-6">
            <h3 class="mb-0">{{ $t("EVENTS.EVENTS_LIST") }}</h3>
          </div>
        </div>
      </div>

      <Event-list-table
        @onViewEvent="openEventViewModal"
        @onEditEvent="openEventEditModal"
        @onDeleteEvent="deleteEvent"
        :key="renderKey * 100"
      />

      <div
        v-if="isViewEventModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isViewEventModalOpened ? 'show' : 'hide']"
        :key="renderKey * 200"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'Event_VIEW'">
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("EVENTS.VIEW_EVENT") }}
              </h1>
            </div>

            <div></div>

            <div class="resizable-wrapper-content-header-right">
              <notification-subscription
                v-if="openEvent"
                :objectType="'Events'"
                :objectId="openEvent.id"
                :events="{
                  UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                  DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                }"
              />

              <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <button class="edit" @click="openEventEditModal(openEvent)">
                  <i class="fal fa-edit"></i>
                  <span>{{ $t("COMMON.EDIT") }}</span>
                </button>
                <button class="delete" @click="deleteEvent(openEvent)">
                  <i class="fal fa-trash-alt"></i>
                  <span>{{ $t("COMMON.DELETE") }}</span>
                </button>
              </base-dropdown>
              <button class="close" @click="closeEventModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <view-Event-component v-if="openEvent" :eventId="openEvent.id" />
          </div>
        </div>
      </div>

      <div
        v-if="isEditEventModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isEditEventModalOpened ? 'show' : 'hide']"
        :key="renderKey * 300"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'Event'">
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("EVENTS.EDIT_EVENT") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <button class="view" @click="openEventViewModal(openEvent)">
                  <i class="fal fa-expand-alt"></i>
                  <span>{{ $t("COMMON.VIEW") }}</span>
                </button>
              </base-dropdown>
              <button class="close" @click="closeEventModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <edit-Event-component
              v-if="openEvent"
              :eventId="openEvent.id"
              @onViewEvent="openEventViewModal"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isAddEventModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isAddEventModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'Event'">
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("EVENTS.ADD_EVENT") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <button class="close" @click="closeEventModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <add-Event-component @onViewEvent="openEventViewModal" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import swal from "sweetalert2";
import { Button } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  QUERY_ACTIONS_VIEW,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_ADD,
} from "@/constants/common";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import EventListTable from "./partials/EventListTable.vue";
import EditEventComponent from "./components/EditEventComponent.vue";
import AddEventComponent from "./components/AddEventComponent.vue";
import ViewEventComponent from "./components/ViewEventComponent.vue";

export default {
  layout: "DashboardLayout",

  components: {
    EventListTable,
    NotificationSubscription,
    EditEventComponent,
    AddEventComponent,
    ViewEventComponent,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  computed: {},

  watch: {},

  data() {
    const eventId = this.$route.query.id;
    const action = this.$route.query.action;
    let isViewEventModalOpened = false;
    let isEditEventModalOpened = false;
    let isAddEventModalOpened = false;
    let openEvent = null;
    if (eventId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewEventModalOpened = true;
      } else if (action === QUERY_ACTIONS_EDIT) {
        isEditEventModalOpened = true;
      }
      openEvent = { id: eventId };
    } else if (action === QUERY_ACTIONS_ADD) {
      isAddEventModalOpened = true;
    }
    return {
      isViewEventModalOpened: isViewEventModalOpened,
      isEditEventModalOpened: isEditEventModalOpened,
      isAddEventModalOpened: isAddEventModalOpened,
      openEvent: openEvent,
      renderKey: 1,
    };
  },

  methods: {
    // openEventCreateModal() {
    //   this.closeEventModal();
    //   this.isAddEventModalOpened = true;

    //   history.pushState(
    //     {},
    //     null,
    //     this.$router.resolve({
    //       name: "List Events",
    //       query: { action: QUERY_ACTIONS_ADD },
    //     }).href
    //   );
    // },
    openEventViewModal(event, reRender = false) {
      this.closeEventModal();
      this.openEvent = event;
      this.isViewEventModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Events",
          query: { id: this.openEvent.id, action: QUERY_ACTIONS_VIEW },
        }).href
      );
    },

    openEventEditModal(event) {
      this.closeEventModal();
      this.openEvent = event;
      this.isEditEventModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Events",
          query: { id: this.openEvent.id, action: QUERY_ACTIONS_EDIT },
        }).href
      );
    },

    closeEventModal() {
      this.isAddEventModalOpened = false;
      this.isViewEventModalOpened = false;
      this.isEditEventModalOpened = false;
      this.openEvent = null;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Events",
          query: {},
        }).href
      );
    },

    async deleteEvent(Event) {
      const confirmation = await swal.fire({
        text: this.$t("EVENTS.DELETE_THIS_EVENT"),
        type: "question",
        customClass: {
          popup: "delete-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonText: this.$t("COMMON.YES"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("events/destroy", Event.id);
          this.renderKey++;
          this.closeEventModal();
          this.$notify({
            type: "success",
            message: this.$t("EVENTS.EVENT_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async acceptEvent(Event) {
      const confirmation = await swal.fire({
        title: this.$t("EVENTS.ACCEPT_THIS_EVENT"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("events/accept", Event.id);
          this.renderKey++;
          this.closeEventModal();
          this.$notify({
            type: "success",
            message: this.$t("EVENTS.EVENTS_ACCEPTED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async approveEvent(Event) {
      const confirmation = await swal.fire({
        text: this.$t("EVENTS.APPROVE_THIS_EVENT"),
        type: "question",
        customClass: {
          popup: "delete-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonText:
          this.$t("COMMON.YES") + ", " + this.$t("COMMON.DELETE"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("events/approve", Event.id);
          this.renderKey++;
          this.closeEventModal();
          this.$notify({
            type: "success",
            message: this.$t("EVENTS.EVENTS_APPROVED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>
