<template>
  <form
    class="add-form"
    ref="profile_form"
    @submit.prevent="handleSubmit"
    @keydown.enter.prevent="() => {}"
  >
    <div class="form-wrapper">
      <base-input
        :label="`${$t('COMMON.NAME')} (*)`"
        :placeholder="$t('COMMON.NAME')"
        v-model="event.name"
        @change="onFormChanged()"
      />
      <validation-error :errors="apiValidationErrors.name" />
    </div>

    <div class="form-wrapper">
      <base-input
        :label="`${$t('COMMON.START_AT')} (*)`"
        :placeholder="$t('COMMON.START_AT')"
        v-model="event.start_at"
        @change="onFormChanged()"
      />
      <validation-error :errors="apiValidationErrors.name" />
    </div>

    <div class="form-wrapper">
      <base-input
        :label="`${$t('COMMON.END_AT')} (*)`"
        :placeholder="$t('COMMON.END_AT')"
        v-model="event.end_at"
        @change="onFormChanged()"
      />
      <validation-error :errors="apiValidationErrors.name" />
    </div>

    <div class="form-wrapper">
      <div class="equal-item type">
        <base-input :label="`${$t('COMMON.TYPE')}`">
          <el-select
            :label="$t('COMMON.TYPE')"
            :placeholder="$t('COMMON.TYPE')"
            v-model="event.type"
            @change="
              (type) => {
                event.type = type;
                onFormChanged();
              }
            "
          >
            <el-option
              v-for="(value, key) in eventTypeOptions"
              :key="key"
              :value="value"
              :label="$t(`COMMON.${value}`)"
            />
          </el-select>
        </base-input>
      </div>

      <validation-error :errors="apiValidationErrors.name" />
    </div>

    <div class="submit-wrapper">
      <base-button
        type="button"
        class="btn btn-sm elite-submit"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading"></i>
        {{ event.id ? $t("EVENTS.EDIT_EVENT") : $t("EVENTS.ADD_EVENT") }}
      </base-button>
    </div>
  </form>
</template>

<script>
import { cloneDeep } from "lodash";
import { Select, Option } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import models from "@/constants/models";
import { eventTypeOptions } from "@/constants/common";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import BillingInformationsForm from "@/components/BillingInformationsForm.vue";
import PhoneNumberInput from "@/components/PhoneNumberInput.vue";

export default {
  layout: "DashboardLayout",

  components: {
    BaseInput,
    ValidationError,
    BillingInformationsForm,
    [Select.name]: Select,
    [Option.name]: Option,
    PhoneNumberInput,
  },

  mixins: [formMixin],

  props: ["eventData", "formErrors", "loading"],

  data() {
    return {
      event: { ...this.eventData },
      permissions: [],
      models: models,
      eventTypeOptions,
    };
  },

  created() {},

  methods: {
    async handleSubmit() {
      let eventData = cloneDeep(this.event);
      this.$emit("eventSubmitted", eventData);
    },

    onFormChanged() {
      this.$emit("formChanged");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    eventData(eventData) {
      if (eventData) {
        this.event = {
          ...this.event,
          ...cloneDeep(eventData),
        };
      }
    },
  },
};
</script>
